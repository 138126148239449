// https://codepen.io/sosuke/pen/Pjoqqp

//000000 - black
.filter-000000 {
  filter: invert(0%) sepia(95%) saturate(7447%) hue-rotate(307deg) brightness(109%) contrast(100%);
}

//059881 - green
.filter-059881 {
  filter: invert(37%) sepia(98%) saturate(571%) hue-rotate(127deg)
    brightness(99%) contrast(96%);
}

//862633 - brown
.filter-862633 {
  filter: invert(17%) sepia(48%) saturate(2618%) hue-rotate(327deg)
    brightness(97%) contrast(91%);
}

//ffffff - white
.filter-ffffff {
  filter: invert(100%) sepia(0%) saturate(7461%) hue-rotate(167deg)
    brightness(96%) contrast(114%);
}

//A90433 - red
.filter-A90433 {
  filter: invert(8%) sepia(96%) saturate(5769%) hue-rotate(337deg)
    brightness(88%) contrast(99%);
}

//7f7f7f - grey
.filter-7f7f7f {
  filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(92%)
    contrast(91%);
}

//159881 - green
.filter-159881 {
  filter: invert(45%) sepia(65%) saturate(519%) hue-rotate(120deg)
    brightness(92%) contrast(91%);
}

//2727b2 - blue
.filter-2727b2 {
  filter: invert(12%) sepia(68%) saturate(4656%) hue-rotate(241deg)
    brightness(101%) contrast(104%);
}

//ff3a47 - pink/red
.filter-ff3a47 {
  filter: invert(35%) sepia(47%) saturate(1842%) hue-rotate(323deg)
    brightness(99%) contrast(115%);
}

.filter-7c2d35 {
  filter: invert(23%) sepia(64%) saturate(699%) hue-rotate(306deg)
    brightness(88%) contrast(94%);
}

//2727ab - blue/purple
.filter-2727ab {
  filter: invert(12%) sepia(68%) saturate(3155%) hue-rotate(235deg) brightness(124%) contrast(111%);
}

//4145e5
.filter-4145e5 {
  filter: invert(21%) sepia(71%) saturate(3676%) hue-rotate(237deg) brightness(92%) contrast(95%);
}

//3636b1 
.filter-3636b1 {
  filter: invert(17%) sepia(36%) saturate(7147%) hue-rotate(239deg) brightness(84%) contrast(91%);
}