.header-logo {
  cursor: pointer;
}

.header-navbar {
  display: flex;
  position: absolute;
  top: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 101;
  box-shadow: 0px 0.6rem 1.25rem var(--color-00000014);
  background-color: white;
  padding: 0 3.25rem;
  width: 100%;
  height: 5.75rem;
  pointer-events: unset;
}

.header-navbar-landing {
  display: flex;
  position: absolute;
  top: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 101;
  padding: 0 3.25rem;
  width: 100%;
  height: 5.75rem;
  pointer-events: unset;
}

.header-radios {
  display: flex;
  align-items: center;
}

.header-language-radio {
  margin: 0 !important;
  background-color: unset !important;
  padding: 0 !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.header-language-radio:hover {
  color: var(--color-2727ab) !important;
}

.header-language-radio:focus,
.header-language-radio.focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.header-language-radio:not(:disabled):not(.disabled):active,
.header-language-radio:not(:disabled):not(.disabled).active,
.show > .header-language-radio.dropdown-toggle {
  border-color: none !important;
  background-color: none !important;
  color: var(--color-2727ab) !important;
}

.header-language-radio-landing {
  margin: 0 !important;
  background-color: unset !important;
  padding: 0 !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  font-weight: 400;
}

.header-language-radio-landing:hover {
  color: var(--color-000000) !important;
}

.header-language-radio-landing:focus,
.header-language-radio-landing.focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.header-language-radio-landing:not(:disabled):not(.disabled):active,
.header-language-radio-landing:not(:disabled):not(.disabled).active,
.show > .header-language-radio-landing.dropdown-toggle {
  border-color: none !important;
  background-color: none !important;
  color: var(--color-000000) !important;
}

.header-language-radio-faq {
  margin: 0 !important;
  background-color: unset !important;
  padding: 0 !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  font-weight: 400;
}

.header-language-radio-faq:hover {
  color: var(--color-000000) !important;
}

.header-language-radio-faq:focus,
.header-language-radio-faq.focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.header-language-radio-faq:not(:disabled):not(.disabled):active,
.header-language-radio-faq:not(:disabled):not(.disabled).active,
.show > .header-language-radio-faq.dropdown-toggle {
  border-color: none !important;
  background-color: none !important;
  color: var(--color-000000) !important;
}

.header-separator {
  margin: 0 0.4rem;
}

.header-button {
  margin-left: 10px;
}

@media (max-width: 810px) {
  .header-navbar {
    padding: 0 1.6rem;
  }

  .header-language-radio-landing {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .header-language-radio-faq {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media (max-width: 810px) {
  .header-logo {
    cursor: pointer;
    width: 176px !important;
    height: 30px !important;
  }

  .header-separator {
    margin: 0 0;
  }

  .header-navbar-landing {
    display: flex;
    position: absolute;
    top: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 101;
    padding: 0 1.25rem;
    width: 100%;
    height: 3.75rem;
    pointer-events: unset;
  }

  .header-button {
    position: absolute !important;
    visibility: hidden !important;
    width: 0px;
    height: 0px;
  }
}
