.faq-over-position {
  position: absolute;
  top: 0;
  padding-top: 50px;
  width: 100%;
}

.faq-height {
  height: 500px;
}

.faq-items-margin-top {
  margin-top: 440px;
}

.faq-title-size {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 72px;
  line-height: 110%;
  font-family: "aktiv-grotesk-extended-800";
  white-space: pre;
}

.faq-subtitle-size {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 20px;
  line-height: 130%;
  font-family: "aktiv-grotesk-400";
  white-space: unset;
}

.faq-separator {
  margin: 2rem 0;
  border: 2px solid #eaeaea;
}

@media (max-width: 767px) {
  .faq-title-size {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 24px;
    line-height: 110%;
    font-family: "aktiv-grotesk-extended-800";
    white-space: unset;
  }

  .faq-subtitle-size {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 16px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    white-space: unset;
  }
}
