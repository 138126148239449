.input-date {
  &--picker-container {
    border: 1.5px solid #c2cddd;
    padding: 0 2% 2% 2%;
    height: auto;
  }

  &--picker-day {
    display: flex;
    flex: 0 0 14.28%;
    justify-content: center;
    margin-top: 8px;

    &-button {
      padding: 0;
      width: 36px;
      height: 36px;
    }
  }

  &--picker-selector {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 8px;

    div {
      cursor: pointer;
      font: var(--font-medium-l);
    }

    p {
      margin: 0;
      padding: 0;
      font: var(--font-medium-m);
      text-transform: uppercase;
    }
  }
}

.input-date--picker-day-button.btn-day-selected {
  border-radius: 3rem;
  background-color: #2727ab !important;
  color: white !important;
  &:hover {
    border-radius: 3rem;
    background-color: #2727ab !important;
  }
}

.input-date--picker-day-button.btn-day-disabled {
  border-color: #e5e5e5 !important;
  border-radius: 3rem;
  background-color: #e5e5e5 !important;
  &:hover {
    border-radius: 3rem;
    background-color: #e5e5e5 !important;
  }
}
