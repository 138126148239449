.input-checkbox {
  &--box {
    margin: 0;
    margin-right: 8px;
    padding: 0;
    width: 1rem;
    height: 1rem;
  }

  &--label {
    display: flex !important;
    align-items: center;
    margin: 0;
    padding: 0;
    font: var(--font-medium-m);
  }

  &--error {
    margin: 0;
    padding: 0;
    color: var(--color-error);
    font: var(--font-medium-s);
  }
}
