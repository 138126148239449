.section7-separator {
  margin: 2rem 0;
  border: 2px solid #eaeaea;
}

.section7-dynamic-size-title {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 48px;
  line-height: 105%;
  font-family: "aktiv-grotesk-extended-800";
  white-space: pre;
}

.section7-dynamic-size-subtitle {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 20px;
  line-height: 130%;
  font-family: "aktiv-grotesk-400";
  white-space: pre-line;
}

.section7-dynamic-size-document-title {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 24px;
  line-height: 120%;
  font-family: "aktiv-grotesk-400";
  white-space: pre-line;
}

@media (max-width: 767px) {
  .section7-dynamic-size-title {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 24px;
    line-height: 105%;
    font-family: "aktiv-grotesk-extended-800";
    white-space: unset;
  }

  .section7-dynamic-size-subtitle {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 16px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    white-space: unset;
  }

  .section7-dynamic-size-document-title {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 16px;
    line-height: 120%;
    font-family: "aktiv-grotesk-400";
    white-space: pre-line;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
