:root {
  --font-family-sofia-pro-black-italic: "SofiaPro-Blackitalic";
  --font-family-sofia-pro-black: "SofiaPro-Black";
  --font-family-sofia-pro-bold-italic: "SofiaPro-Bolditalic";
  --font-family-sofia-pro-bold: "SofiaPro-Bold";
  --font-family-sofia-pro-extralight-italic: "SofiaPro-ExtraLightitalic";
  --font-family-sofia-pro-extralight: "SofiaPro-ExtraLight";
  --font-family-sofia-pro-light-italic: "SofiaPro-Lightitalic";
  --font-family-sofia-pro-light: "SofiaPro-Light";
  --font-family-sofia-pro-medium-italic: "SofiaPro-Mediumitalic";
  --font-family-sofia-pro-medium: "SofiaPro-Medium";
  --font-family-sofia-pro-regular-italic: "SofiaPro-Regularitalic";
  --font-family-sofia-pro-semibold-italic: "SofiaPro-SemiBolditalic";
  --font-family-sofia-pro-semibold: "SofiaPro-SemiBold";
  --font-family-sofia-pro: "SofiaPro-Regular";
}

$sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
  81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  100;

$weights: 100, 200, 300, 400, 500, 600, 700, 800, 900, bold, bolder, lighter,
  normal;

/**
 * Sofia Pro Black Italic
 */
@each $size in $sizes {
  .font-#{$size}-black-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-black-italic);
  }
}

/**
 * Sofia Pro Black
 */
@each $size in $sizes {
  .font-#{$size}-black {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-black);
  }
}

/**
 * Sofia Pro Bold Italic
 */
@each $size in $sizes {
  .font-#{$size}-bold-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-bold-italic);
  }
}

/**
 * Sofia Pro Bold
 */
@each $size in $sizes {
  .font-#{$size}-bold {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-bold);
  }
}

/**
 * Sofia Pro Extralight Italic
 */
@each $size in $sizes {
  .font-#{$size}-extralight-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-extralight-italic);
  }
}

/**
 * Sofia Pro Extralight
 */
@each $size in $sizes {
  .font-#{$size}-extralight {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-extralight);
  }
}

/**
 * Sofia Pro Light Italic
 */
@each $size in $sizes {
  .font-#{$size}-light-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-light-italic);
  }
}

/**
 * Sofia Pro Light
 */
@each $size in $sizes {
  .font-#{$size}-light {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-light);
  }
}

/**
 * Sofia Pro Medium Italic
 */
@each $size in $sizes {
  .font-#{$size}-medium-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-medium-italic);
  }
}

/**
 * Sofia Pro Medium
 */
@each $size in $sizes {
  .font-#{$size}-medium {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-medium);
  }
}

/**
 * Sofia Pro Regular Italic
 */
@each $size in $sizes {
  .font-#{$size}-regular-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-regular-italic);
  }
}

/**
 * Sofia Pro Semibold Italic
 */
@each $size in $sizes {
  .font-#{$size}-semibold-italic {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-semibold-italic);
  }
}

/**
 * Sofia Pro Semibold
 */
@each $size in $sizes {
  .font-#{$size}-semibold {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro-semibold);
  }
}

/**
 * Sofia Pro
 */
@each $size in $sizes {
  .font-#{$size} {
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
    font-family: var(--font-family-sofia-pro);
  }
}

@each $size in $sizes {
  .font-grotesk-#{$size} {
    font-size: #{$size}px;
    font-family: "neue-haas-grotesk-display";
  }
}

@each $weight in $weights {
  .font-weight-#{$weight} {
    font-weight: #{$weight};
  }
}

@each $size in $sizes {
  .line-height-#{$size} {
    line-height: #{$size}px;
  }
}

@media (max-width: 767px) {
  .font-grotesk-48 {
    font-size: 32px;
    font-family: "neue-haas-grotesk-display";
  }

  .font-grotesk-40 {
    font-size: 32px;
    font-family: "neue-haas-grotesk-display";
  }

  .font-grotesk-24 {
    font-size: 16px;
    font-family: "neue-haas-grotesk-display";
  }

  .font-grotesk-20 {
    font-size: 18px;
    font-family: "neue-haas-grotesk-display";
  }
}
