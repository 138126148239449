:root {
  /* Colors: */
  --color-ffffffbf: #ffffffbf;
  --color-e5e5e5: #e5e5e5;
  --color-15819e: #15819e;
  --color-fc2f00: #fc2f00;
  --color-00a720: #00a720;
  --color-eb5a00: #eb5a00;
  --color-bfbfbf: #bfbfbf;
  --color-7f7f7f: #7f7f7f;
  --color-81cbd6: #81cbd6;
  --color-acd856: #acd856;
  --color-ffbe00: #ffbe00;
  --color-ffffff: #ffffff;
  --color-059881: #059881;
  --color-eaf8f5: #eaf8f5;
  --color-f7f7f7: #f7f7f7;
  --color-000000: #000000;
  --color-862633: #862633;
  --color-891124: #891124;
  --color-C2CDDD: #c2cddd;
  --color-a90433: #a90433;
  --color-ab243c: #ab243c;
  --color-db2344: #db2344;
  --color-ffffff80: #ffffff80;
  --color-159881: #159881;
  --color-f7efef: #f7efef;
  --color-aae0ff: #aae0ff;
  --color-4642e4: #4642e4;
  --color-a4dbff: #a4dbff;
  --color-2727b2: #2727b2;
  --color-292929A3: #292929a3;
  --color-f5e9da: #f5e9da;
  --color-7c2d35: #7c2d35;
  --color-ff3a47: #ff3a47;
  --color-0067de: #0067de;
  --color-292929: #292929;
  --color-0000001a: rgba(0, 0, 0, 0.1);
  --color-00000040: rgba(0, 0, 0, 0.25);
  --color-00000080: rgba(0, 0, 0, 0.5);
  --color-0000004D: rgba(0, 0, 0, 0.3);
  --color-7f7f7f-0_25: rgba(127, 127, 127, 0.25);
  --color-7f7f7f-0_80: rgba(127, 127, 127, 0.8);
  --color-000000E6: rgba(0, 0, 0, 0.9);
  --color-00000014: rgba(0, 0, 0, 0.08);
  --color-059881-hover: #06b197;
  --color-059881-upload: rgba(6, 177, 151, 0.7);

  /**
   * Backgrounds
   */
  .bg-e5e5e5 {
    background-color: var(--color-e5e5e5);
  }
  .bg-000000E6 {
    background-color: var(--color-000000E6);
  }
  .bg-15819e {
    background-color: var(--color-15819e);
  }
  .bg-fc2f00 {
    background-color: var(--color-fc2f00);
  }
  .bg-00a720 {
    background-color: var(--color-00a720);
  }
  .bg-eb5a00 {
    background-color: var(--color-eb5a00);
  }
  .bg-bfbfbf {
    background-color: var(--color-bfbfbf);
  }
  .bg-7f7f7f {
    background-color: var(--color-7f7f7f);
  }
  .bg-81cbd6 {
    background-color: var(--color-81cbd6);
  }
  .bg-acd856 {
    background-color: var(--color-acd856);
  }
  .bg-ffbe00 {
    background-color: var(--color-ffbe00);
  }
  .bg-ffffff {
    background-color: var(--color-ffffff);
  }
  .bg-059881 {
    background-color: var(--color-059881);
  }
  .bg-eaf8f5 {
    background-color: var(--color-eaf8f5);
  }
  .bg-f7f7f7 {
    background-color: var(--color-f7f7f7);
  }
  .bg-000000 {
    background-color: var(--color-000000);
  }
  .bg-862633 {
    background-color: var(--color-862633);
  }
  .bg-891124 {
    background-color: var(--color-891124);
  }
  .bg-c2cddd {
    background-color: var(--color-C2CDDD);
  }
  .bg-a90433 {
    background-color: var(--color-a90433);
  }
  .bg-159881 {
    background-color: var(--color-159881);
  }
  .bg-f7efef {
    background-color: var(--color-f7efef);
  }
  .bg-ffffff80 {
    background-color: var(--color-ffffff80);
  }
  .bg-00000080 {
    background-color: var(--color-00000080);
  }
  .bg-ab243c {
    background-color: var(--color-ab243c);
  }
  .bg-db2344 {
    background-color: var(--color-db2344);
  }
  .bg-aae0ff {
    background-color: var(--color-aae0ff);
  }
  .bg-4642e4 {
    background-color: var(--color-4642e4);
  }
  .bg-a4dbff {
    background-color: var(--color-a4dbff);
  }
  .bg-f5e9da {
    background-color: var(--color-f5e9da);
  }
  .bg-7c2d35 {
    background-color: var(--color-7c2d35);
  }
  .bg-0067de {
    background-color: var(--color-0067de);
  }
  .bg-292929 {
    background-color: var(--color-292929);
  }

  /**
   * Colors
   */
  .c-ffffffbf {
    color: var(--color-ffffffbf);
  }
  .c-e5e5e5 {
    color: var(--color-e5e5e5);
  }
  .c-15819e {
    color: var(--color-15819e);
  }
  .c-fc2f00 {
    color: var(--color-fc2f00);
  }
  .c-00a720 {
    color: var(--color-00a720);
  }
  .c-eb5a00 {
    color: var(--color-eb5a00);
  }
  .c-bfbfbf {
    color: var(--color-bfbfbf);
  }
  .c-7f7f7f {
    color: var(--color-7f7f7f);
  }
  .c-81cbd6 {
    color: var(--color-81cbd6);
  }
  .c-acd856 {
    color: var(--color-acd856);
  }
  .c-ffbe00 {
    color: var(--color-ffbe00);
  }
  .c-ffffff {
    color: var(--color-ffffff);
  }
  .c-059881 {
    color: var(--color-059881);
  }
  .c-eaf8f5 {
    color: var(--color-eaf8f5);
  }
  .c-f7f7f7 {
    color: var(--color-f7f7f7);
  }
  .c-000000 {
    color: var(--color-000000);
  }
  .c-862633 {
    color: var(--color-862633);
  }
  .c-891124 {
    color: var(--color-891124);
  }
  .c-c2cddd {
    color: var(--color-C2CDDD);
  }
  .c-a90433 {
    color: var(--color-a90433);
  }
  .c-159881 {
    color: var(--color-159881);
  }
  .c-f7efef {
    color: var(--color-f7efef);
  }
  .c-292929A3 {
    color: var(--color-292929A3);
  }
  .c-2727b2 {
    color: var(--color-2727b2);
  }
  .c-f5e9da {
    color: var(--color-f5e9da);
  }
  .c-7c2d35 {
    color: var(--color-7c2d35);
  }
  .c-0067de {
    color: var(--color-0067de);
  }
  .c-292929 {
    color: var(--color-292929);
  }
  .c-ff3a47 {
    color: var(--color-ff3a47);
  }
  .c-00000080 {
    color: var(--color-00000080);
  }
  .c-ffffff80 {
    color: var(--color-ffffff80);
  }
  .c-0000001a {
    color: var(--color-0000001a);
  }
}
