.input-text {
  &--label {
    margin: 0;
    padding: 0;
    font: var(--font-medium-s);
  }

  &--failure {
    margin: 0;
    padding: 0;
    font: var(--font-medium-s);
  }

  &--error {
    @extend .input-text--failure;
    color: var(--color-error);
  }

  &--warning {
    @extend .input-text--failure;
    color: var(--color-warning);
  }

  &--field {
    display: flex;
    min-height: 46px !important;

    &-disabled {
      cursor: not-allowed;
      background-color: #efefef;
      color: var(--color-disabled);
    }
  }

  &--input {
    flex: 1;
    outline: 0;
    border: none;
    border-radius: 5px !important; // REMOVE AFTER REMOVED THE REACT-COMPONENTS-LIBRARY
    padding: 8px 12px;
    font: var(--font-medium-m);
  }

  &--border {
    &-normal {
      border: 1px solid #bfbfbf !important;
      border-radius: 5px !important;
    }
    &-error {
      border: 1px solid var(--color-error) !important;
      border-radius: 5px !important;
    }
    &-warning {
      border: 1px solid var(--color-warning) !important;
      border-radius: 5px !important;
    }
  }

  &--icon {
    padding: 0 8px;
    border-radius: 5px !important;
  }

  &--icon-valid {
    border-radius: 5px !important;
    background-color: white;
    padding: 0 8px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
