.section6-dynamic-size-pretitle {
  font-size: 14px;
  font-family: "aktiv-grotesk-extended-500";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 120%;
  background: rgba(219, 219, 220, 0.5);
  border-radius: 4px;
}

.section6-dynamic-size-title {
  font-size: 48px;
  font-family: "aktiv-grotesk-extended-800";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 105%;
  white-space: pre;
}

.section6-dynamic-size-subtitle {
  font-size: 20px;
  font-family: "aktiv-grotesk-400";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  white-space: pre-line;
  line-height: 130%;
}

.section6-dynamic-size-partners-title {
  font-size: 20px;
  font-family: "aktiv-grotesk-400";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  white-space: pre-line;
  line-height: 130%;
}

@media (max-width: 767px) {
  .section6-dynamic-size-title {
    font-size: 24px;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 105%;
    white-space: unset;
  }

  .section6-dynamic-size-subtitle {
    font-size: 16px;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    white-space: unset;
    line-height: 130%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section6-dynamic-size-partners-title {
    font-size: 16px;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    white-space: pre-line;
    line-height: 130%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
