:root {
  --font-family-sofia-pro-black: "SofiaPro-Black";
  --font-family-sofia-pro-bold: "SofiaPro-Bold";
  --font-family-sofia-pro-semibold: "SofiaPro-SemiBold";
  --font-family-sofia-pro-medium: "SofiaPro-Medium";
  --font-family-sofia-pro-regular: "SofiaPro-Regular";
  --font-family-sofia-pro-light: "SofiaPro-Light";
  --font-family-sofia-pro-extralight: "SofiaPro-ExtraLight";
  --font-family-sofia-pro-black-italic: "SofiaPro-Blackitalic";
  --font-family-sofia-pro-bold-italic: "SofiaPro-Bolditalic";
  --font-family-sofia-pro-semibold-italic: "SofiaPro-SemiBolditalic";
  --font-family-sofia-pro-medium-italic: "SofiaPro-Mediumitalic";
  --font-family-sofia-pro-regular-italic: "SofiaPro-Regularitalic";
  --font-family-sofia-pro-light-italic: "SofiaPro-Lightitalic";
  --font-family-sofia-pro-extralight-italic: "SofiaPro-ExtraLightitalic";

  --font-size-xxs: 0.7rem;
  --font-size-xs: 0.8rem;
  --font-size-s: 0.9rem;
  --font-size-m: 1rem;
  --font-size-l: 1.1rem;
  --font-size-xl: 1.2rem;
  --font-size-xxl: 1.3rem;

  --font-medium-s: var(--font-size-s) var(--font-family-sofia-pro-medium);
  --font-medium-m: var(--font-size-m) var(--font-family-sofia-pro-medium);
  --font-medium-l: var(--font-size-l) var(--font-family-sofia-pro-medium);

  .font-medium-s {
    font: var(--font-medium-s);
  }

  .font-medium-m {
    font: var(--font-medium-m);
  }

  .font-medium-l {
    font: var(--font-medium-l);
  }
}
