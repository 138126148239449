@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.loader-modal {
  .modal-content {
    display: flex;
    align-items: center;
    border: unset;
    background-color: transparent;
  }
}

.loader {
  display: flex;
  justify-content: center;
  transform: matrix(-0.5, 0.87, -0.87, -0.5, 0, 0);
  animation: rotate steps(50) 1s infinite;
  width: 4rem;
  height: 4rem;
}
