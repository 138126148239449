.border-error {
  border: 1px solid red;
  border-radius: 10px;
}

.info-box {
  border: 1px solid #3636b1;
  border-radius: 8px;
  background-color: #3636b10d;
}

.group-info-separator {
  border: 1px solid #cfcfcf;
}

.group-square {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #3636b1;
  width: 15px !important;
  height: 15px !important;
}
