.icon {
  transform: rotate(90deg);
  transition: 0.5s;
  margin-right: 0.25rem;
}

.rotate {
  transform: rotate(-90deg);
}

.separator {
  margin: 0;
  padding: 0;

  &-green {
    background-color: #159881;
  }
  &-grey {
    background-color: #ffffff80;
  }
}
