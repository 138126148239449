.modal-width {
  max-width: unset !important;

  @media screen and (min-width: 576px) {
    width: 80% !important; // sm (Responsive)
  }
  @media screen and (min-width: 768px) {
    width: 70% !important; // md (iPad)
  }
  @media screen and (min-width: 992px) {
    width: 70% !important; // lg (iPad Pro)
  }
  @media screen and (min-width: 1200px) {
    width: 60% !important; // xl (Desktop)
  }
  @media screen and (min-width: 1400px) {
    width: 50% !important; // xxl (Exra Desktop)
  }

  @media (max-width: 575px) {
    .modal-content {
      position: absolute;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      margin: 0 !important;
      padding: 0 !important;
      max-width: unset !important;
      overflow-y: scroll;
    }
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }
}
