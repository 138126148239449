.digit {
  margin: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.disabled_digit {
  opacity: 0.8;
  background-color: lightgray !important;
}

.error_digit {
  border: 2px solid #7c2d35 !important;
  color: #7c2d35;
}

.success_digit {
  border: 2px solid #059881 !important;
  color: #059881;
}

.max_digits_width {
  max-width: 250px;
}
