.axepta-sdk-button-contained {
  background-color: var(--color-4145e5) !important;
  font: var(--font-medium-m) !important;
  font-size: 20px !important;
}

.axepta-sdk-back-button {
  background-color: var(--color-4145e5) !important;
}

.axepta-sdk-textfield-outlined {
  flex: 1 1 !important;
  outline: rgb(118, 118, 118) solid 1px !important;
  border-radius: 5px !important;
  padding: 8px 12px !important;
  font: var(--font-medium-m) !important;
}

.axepta-sdk-button-contained-selector {
  font: var(--font-medium-m) !important;
  font-size: 20px !important;
}

.axepta-sdk-textfield-outlined:focus {
  border: 1px solid var(--color-4145e5) !important;
}

.axepta-sdk-textfield-outlined:focus:valid {
  outline: 3px solid var(--color-4145e5) !important;
}

.axepta-sdk-loader {
  border-top: 8px solid var(--color-4145e5) !important;
}

.axepta-modal{
  z-index: 105 !important;
}

@media (max-width: 767px) {
  .axepta-sdk-card {
    margin: 0 !important;
    width: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
