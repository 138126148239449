button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0.8rem 1.5rem;
  font-weight: 500;
  font-size: var(--font-size-m);
  font-family: var(--font-family-sofia-pro-medium);

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0;
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.primary {
  background-color: var(--color-primary);
  color: white;

  &-flip {
    background-color: white;
    color: var(--color-primary);

    &-border {
      border: solid 2px var(--color-primary);
      background-color: white;
      color: var(--color-primary);
    }
  }
}

.accent {
  background-color: var(--color-4145e5) !important; // REMOVE AFTER REMOVED THE REACT-COMPONENTS-LIBRARY
  color: white;

  &-flip {
    background-color: white;
    color: var(--color-secondary);

    &-border {
      border: solid 2px var(--color-secondary);
      background-color: white;
      color: var(--color-secondary);
    }
  }
}

.transparent {
  &-primary {
    background-color: transparent;
    color: var(--color-primary);
  }
  &-accent {
    background-color: transparent;
    color: var(--color-141414) !important; // REMOVE AFTER REMOVED THE REACT-COMPONENTS-LIBRARY
  }
  &-white {
    background-color: transparent;
    color: white;

    &-border {
      border: solid 2px white;
      background-color: transparent;
      color: white;
    }
  }
}
