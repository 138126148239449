.margin-icon {
  margin-bottom: 2px;
}

.margin-info {
  margin-top: 2.6rem;
}

.white-box {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  background-color: #ffffff;
}

.package-box-selected {
  border: 1px solid #3636b1;
  border-radius: 8px;
  background-color: #3636b10d;
}

.white-box,
.package-box-selected {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.white-box:hover,
.package-box-selected:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.white-box-info {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  background-color: #ffffff;
}

.bullet-info {
  border: 2px solid #3636b1;
  border-radius: 50%;
  width: 9px !important;
  height: 9px;
}

@media (max-width: 767px) {
  .margin-info {
    margin-top: 0rem;
  }

  .white-box,
  .package-box-selected {
    transition: unset;
  }

  .white-box:hover,
  .package-box-selected:hover {
    transform: unset;
    box-shadow: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
