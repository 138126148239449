.section4-dynamic-size-title {
  font-size: 48px;
  font-family: "aktiv-grotesk-extended-800";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 110%;
}

.section4-dynamic-size-subtitle {
  font-size: 20px;
  font-family: "aktiv-grotesk-400";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 130%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Hide the default YouTube controls */
iframe[src*="youtube.com/embed"] {
  pointer-events: none;
}

/* Add a play button overlay */
iframe[src*="youtube.com/embed"]:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

@media (max-width: 767px) {
  .section4-dynamic-size-title {
    font-size: 24px;
    line-height: 24px;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 110%;
  }

  .section4-dynamic-size-subtitle {
    font-size: 16px;
    line-height: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 130%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
