.section5-dynamic-size-title {
  font-size: 88px;
  font-family: "aktiv-grotesk-extended-800";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 100%;
}

.section5-dynamic-size-subtitle {
  font-size: 20px;
  font-family: "aktiv-grotesk-400";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  white-space: pre-line;
  line-height: 130%;
}

.section5-carousel-inner {
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .section5-dynamic-size-title {
    font-size: 40px;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 100%;
  }

  .section5-dynamic-size-subtitle {
    font-size: 16px;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    white-space: unset;
    line-height: 130%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section5-carousel-inner {
    .carousel-inner {
      display: flex;
      align-items: center;
      min-height: 400px;
    }

    .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
