.click-height {
  line-height: 15.6px;
}

.button-submit {
  margin: auto;
}

.info-box-discount {
  border: 1px solid #3636b1;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .button-submit {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}