$bg-tooltip: var(--color-ffffff);

#tooltip-wrapper {
  opacity: 1;
}

#tooltip-wrapper > .tooltip-inner {
  background-color: $bg-tooltip;
  color: #000;
  box-shadow: 0.5rem 0.5rem 1rem var(--color-7f7f7f) !important;
  padding: 0.75rem 1rem;
  max-width: fit-content;
}

.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $bg-tooltip !important;
}
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $bg-tooltip !important;
}
.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: $bg-tooltip !important;
}
.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: $bg-tooltip !important;
}
