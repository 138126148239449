.pointer {
  cursor: pointer;
}

.drag-drop {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: #fff;
}

.document-uploader {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 2rem;

  &.active {
    border-color: var(--color-4145e5);
  }

  .upload-info {
    margin-bottom: 1rem;

    svg {
      margin-right: 1rem;
      font-size: 36px;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
      }

      p:first-child {
        font-weight: bold;
      }
    }
  }

  .file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .file-item {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0.5rem;

    .file-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 0.25rem;

      p {
        margin: 0;
        color: #333;
        font-size: 14px;
      }
    }

    .file-actions {
      cursor: pointer;

      svg {
        color: #888;
        font-size: 18px;
      }

      &:hover {
        svg {
          color: #d44;
        }
      }
    }
  }

  .browse-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--primary-color);
    padding: 0.5rem 1rem;
    &:hover {
      background-color: transparent;
    }
  }

  .success-file {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .internal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input[type="file"] {
    display: none;
  }
}

.border-neutral {
  border: 2px dashed var(--color-4145e5);
}

.border-ok {
  border: 2px dashed #00b341 !important;
}

.border-partial-errors {
  border: 2px dashed #ff8c00 !important;
}

.border-errors {
  border: 2px dashed #ff0000 !important;
}

.success-box {
  border-radius: 100px;
  background-color: #e3fde1;
  padding: 0.5rem 1rem;
}

.errors-box {
  border-radius: 100px;
  background-color: #fde3e1;
  padding: 0.5rem 1rem;
}
