.input-radio {
  &--label {
    margin: 0;
    padding: 0;
    font: var(--font-medium-s);
  }

  &--error {
    margin: 0;
    padding: 0;
    color: var(--color-error);
    font: var(--font-medium-s);
  }

  &--options {
    &-h {
      display: flex;
    }
    &-v {
      display: flex;
      flex-direction: column;
    }
  }

  &--option {
    display: flex;
    align-items: center;
    font: var(--font-medium-s);
  }
}
