.home {
  &--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom right, #021b79, #0575e6);
    min-height: 100vh;
    color: white;
    font-size: calc(10px + 2vmin);
    text-align: center;
  }

  &--logo {
    width: 500px;
    max-width: 80%;
  }

  &--link {
    margin-top: 100px;
    color: #ffc000;
  }
}
