.showcases {
  &--container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 100vh;

    hr {
      margin-top: 20px;
    }
  }

  &--loader {
    margin: 10px 0;
  }

  &--button {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    background-color: #2d2d2d;

    button {
      margin: 10px;
    }
  }

  &--accordion {
    margin: 10px 0;
    width: 1000px;
    max-width: 100%;
  }

  &--modal {
    display: flex;
    margin: 10px 0;
  }

  &--formik {
    margin: 10px 0;
    max-width: 100%;
  }
}
