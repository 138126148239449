.text-underline-grey {
  color: rgba(20, 20, 20, 0.7);
  text-decoration: underline rgba(20, 20, 20, 0.7);
}

.section2-dynamic-size-title {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 48px;
  line-height: 110%;
  font-family: "aktiv-grotesk-extended-800";
  white-space: pre;
}

.section2-dynamic-size-info {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 22px;
  line-height: 70%;
  font-family: "aktiv-grotesk-extended-500";
  white-space: pre;
}

.section2-dynamic-size-subtitle {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 20px;
  line-height: 130%;
  font-family: "aktiv-grotesk-400";
  white-space: pre;
}

.periods-border {
  border-bottom: 1.5px solid #eaeaea;
}

.selected-period-border {
  border-bottom: 2px solid #4145e5;
}

.unselected-period-border {
  background-color: unset;
}

.label-opacity-grey {
  color: rgba(20, 20, 20, 0.7);
}

.section2-card {
  border: 2px solid #eaeaea;
  border-radius: 4px;
  background: #ffffff;
  min-height: 550px;
}

.selected-package {
  border: 2px solid #4145e5;
  border-radius: 4px;
  background: rgba(65, 69, 229, 0.2);
  min-height: 550px;
}

.section2-separator {
  margin: 1rem 0;
  border: 2px solid #eaeaea;
}

.section2-open-icon {
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .unselected-period-border {
    border-radius: 4px;
    background-color: #ffffff;
  }

  .section2-dynamic-size-title {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 24px;
    line-height: 110%;
    font-family: "aktiv-grotesk-extended-800";
    white-space: unset;
  }

  .section2-dynamic-size-info {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 20px;
    line-height: 50%;
    font-family: "aktiv-grotesk-extended-500";
    white-space: pre;
  }

  .section2-dynamic-size-subtitle {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 16px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    white-space: unset;
  }

  .periods-border {
    border-bottom: unset;
  }

  .selected-period-border {
    border-bottom: unset;
    border: 1px solid #4145e5;
    border-radius: 4px;
    background: linear-gradient(
        0deg,
        rgba(65, 69, 229, 0.2),
        rgba(65, 69, 229, 0.2)
      ),
      #ffffff;
  }

  .section2-card {
    border: 2px solid #eaeaea;
    border-radius: 4px;
    background: #ffffff;
    min-height: unset;
  }

  .section2-card-reimbursement {
    min-height: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section2-dynamic-size-title {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 40px;
    line-height: 110%;
    font-family: "aktiv-grotesk-extended-800";
    white-space: pre;
  }

  .section2-dynamic-size-info {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 18px;
    line-height: 50%;
    font-family: "aktiv-grotesk-extended-500";
    white-space: pre;
  }

  .section2-dynamic-size-subtitle {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 20px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    white-space: pre;
  }

  .selected-package {
    border: 2px solid #4145e5;
    border-radius: 4px;
    background: rgba(65, 69, 229, 0.2);
  }

  .section2-space-0 {
    min-height: 250px;
  }

  .section2-space-1 {
    border-bottom: 2px solid #eaeaea;
    min-height: 400px;
  }

  .section2-space-2 {
    border-bottom: 2px solid #eaeaea;
    min-height: 400px;
  }

  .section2-space-3 {
    min-height: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .selected-package {
    border: 2px solid #4145e5;
    border-radius: 4px;
    background: rgba(65, 69, 229, 0.2);
  }

  .section2-space-0 {
    min-height: 200px;
  }

  .section2-space-1 {
    border-bottom: 2px solid #eaeaea;
    min-height: 250px;
  }

  .section2-space-2 {
    border-bottom: 2px solid #eaeaea;
    min-height: 200px;
  }

  .section2-space-3 {
    min-height: 300px;
  }
}

@media (min-width: 1281px) {
  .selected-package {
    border: 2px solid #4145e5;
    border-radius: 4px;
    background: rgba(65, 69, 229, 0.2);
  }
  
  .section2-space-0 {
    min-height: 200px;
  }

  .section2-space-1 {
    border-bottom: 2px solid #eaeaea;
    min-height: 200px;
  }

  .section2-space-2 {
    border-bottom: 2px solid #eaeaea;
    min-height: 200px;
  }

  .section2-space-3 {
    min-height: 350px;
  }
}
