.info-phone-space {
  margin-left: 0.7rem;
}

.info-space {
  margin-left: 2rem;
}

.footer-line {
  border-bottom: 1px solid var(--color-ffffff80);
}

@media (max-width: 810px) {
  .info-space {
    margin-left: 0rem;
  }
}
