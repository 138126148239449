.position-discount-button {
  position: absolute;
  top: 12.47rem;
}

@media (max-width: 767px) {
  .position-discount-button {
    position: relative;
    top: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
