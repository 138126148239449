.phone-style {
  .react-tel-input {
    max-height: 46px !important;

    .form-control {
      transition: unset !important;
      outline: none;
      border: 1px solid #bfbfbf !important;
      border-radius: 5px !important;
      background: #ffffff;
      padding: 9px 12px 9px 60px !important;
      width: 100% !important;
      color: #000000;
      font: var(--font-medium-m);
    }

    .form-control:focus {
      outline: 0;
      box-shadow: unset !important;
      border-color: #bfbfbf !important;
      background-color: #ffffff !important;
    }

    .selected-flag:focus:before {
      box-shadow: unset !important;
      border-color: transparent !important;
      background-color: #ffffff !important;
    }
    .selected-flag.open:before {
      box-shadow: unset !important;
      border-color: transparent !important;
      background-color: #ffffff !important;
    }
  }
}

.phone-style-error {
  .react-tel-input {
    max-height: 46px !important;

    .form-control {
      transition: unset !important;
      outline: none;
      border: 0.5px solid var(--color-error) !important;
      border-radius: 5px !important;
      background: #ffffff;
      padding: 9px 12px 9px 60px !important;
      width: 100% !important;
      color: #000000;
      font: var(--font-medium-m);
    }

    .form-control:focus {
      outline: 0;
      box-shadow: unset !important;
      border-color: #bfbfbf !important;
      border: 0.5px solid var(--color-error) !important;
      background-color: #ffffff !important;
    }

    .selected-flag:focus:before {
      box-shadow: unset !important;
      border-color: transparent !important;
      background-color: #ffffff !important;
    }
    .selected-flag.open:before {
      box-shadow: unset !important;
      border-color: transparent !important;
      background-color: #ffffff !important;
    }
  }
}
