.section2-title-news {
  border-radius: 4px;
  margin: 0 4rem;
  padding: 0.5rem 0rem;
  width: 10rem;
  background: rgba(0, 0, 0, 0.04);
}

.section2-card-special {
  border: 2px solid #eaeaea;
  border-radius: 4px;
  background: #ffffff;
}

.section2-special-dynamic-size-title {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 48px;
  line-height: 110%;
  font-family: "aktiv-grotesk-extended-800";
  white-space: pre;
}

.section2-special-dynamic-size-subtitle {
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  font-size: 20px;
  line-height: 130%;
  font-family: "SofiaPro-Regular";

  .bold {
    font-family: "SofiaPro-Bold";
  }
}

.image-radius {
  border-radius: 10px;
}

@media (max-width: 767px) {
  .section2-special-dynamic-size-title {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 24px;
    line-height: 110%;
    font-family: "aktiv-grotesk-extended-800";
    white-space: unset;
  }

  .section2-special-dynamic-size-subtitle {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 16px;
    line-height: 130%;
    font-family: "SofiaPro-Regular";

    .bold {
      font-family: "SofiaPro-Bold";
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section2-special-dynamic-size-title {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 40px;
    line-height: 110%;
    font-family: "aktiv-grotesk-extended-800";
    white-space: pre;
  }

  .section2-special-dynamic-size-subtitle {
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    font-size: 20px;
    line-height: 130%;
    font-family: "SofiaPro-Regular";

    .bold {
      font-family: "SofiaPro-Bold";
    }
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) {
}
