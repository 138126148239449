.cursor-auto {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.white-space-pre {
  white-space: pre-line;
}

.display-contents {
  display: contents;
}

.line-over {
  text-decoration: line-through;
  text-decoration-style: solid;
}

.border-underline-link {
  border-bottom: 1px solid var(--color-862633);
}

.border-underline-link-3636b1 {
  border-bottom: 1px solid var(--color-3636b1);
}

.border-underline-link-000000 {
  border-bottom: 1px solid var(--color-000000);
}

.max-w-fit-content {
  max-width: fit-content;
}

.claim-card-bg {
  background-color: rgba(245, 233, 219, 0.43);
}

.text-underline {
  text-decoration: underline;
}

.claim-progress {
  .progress {
    display: flex;
    border-radius: unset !important;
    background-color: #ebebeb;
    height: 0.5rem;
    overflow: hidden;
    font-size: 0.75rem;
  }
}

.claim-progress-bar {
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: width 0.6s ease;
    background-color: #059881;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
  }
}

.claim-card {
  cursor: pointer;
  border: 2px solid transparent;
  min-width: 135px;
  min-height: 146px;
  &:hover {
    border: 2px solid #7c2d35;
  }
}

.selected-claim-card {
  cursor: pointer;
  border: 2px solid #7c2d35;
  // box-shadow: 0px 0px 0px 1.5px #7c2d35;
  min-width: 135px;
  min-height: 146px;
}

.option-claim-item {
  border: 1px solid #7c2d35;
  border-radius: 16px;
  background-color: #7c2d35;
  padding: 5px 7px;
  color: #ffffff;
}

.border-tag {
  border: 1px solid #4642e4;
  border-radius: 16px;
  background-color: #4642e4;
  width: fit-content;
  min-width: 100px;
}

.border-bottom-green {
  opacity: 40%;
  border-bottom: 1px solid #059881;
}

.border-time-slot {
  border: 1px solid #7c2d35;
}

.shadow-time-slot {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.11);
}

.claim-form-control-hour {
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    padding: 8px 12px;
  }

  input[type="time"] {
    position: relative;
    z-index: 0;
  }

  input[type="time"]:after {
    display: flex;
    position: absolute;
    right: 9px;
    z-index: -1;
    margin-left: 10px;
    background-image: url("../assets/icons/time.icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 8px 12px;

    width: 20px;
    height: 20px;
    content: "";
    font: var(--font-medium-s);
  }

  .form-control {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid #bfbfbf !important;
    border-radius: 0;
    background-clip: padding-box;
    background-color: #fff;

    padding: 6px 15px !important;
    width: 100%;
    color: #212529;
    font: var(--font-medium-s);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;

    &:focus {
      box-shadow: none;
      border-color: none;
    }
  }
}

.claim-border-bottom-green {
  opacity: 0.4;
  border-bottom: 1px solid #059881;
}

.text-upp-placeholder-low {
  input {
    text-transform: uppercase;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none !important;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none !important;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none !important;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none !important;
  }
  ::placeholder {
    /* Recent browsers */
    text-transform: none !important;
  }
}
