.slider-logos-container {
  overflow: hidden !important;
}

.logo-box {
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  width: 200px;
  height: 100px;
}



@media (max-width: 767px) {
  .logo-box {
    align-items: center;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    width: 150px;
    height: 75px;
  }
  
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) {
}
