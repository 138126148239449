.section3-align-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.section3-dynamic-size-title {
  font-size: 48px;
  font-family: "aktiv-grotesk-extended-800";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 105%;
}

@media (max-width: 767px) {
  .section3-dynamic-size-title {
    font-size: 24px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 105%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1280px) {
}

@media (min-width: 1281px) and (max-width: 1920px) {
}
